@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
    --primary-color: #7144B3;
    --secondary-color: #008CB7;
    --accent-color: #00c7b1;
    --dark-purple: #39225F;
    --mid-purple: #8C5FCC;
    --light-purple: #9b42ef;
    --text-color: #fff;
    --text-color-dark: #000;
    --background-color: #f5f5f5;
}

.landing-page {
    font-family: 'Rubik', sans-serif;
}

.bg-purple {
    background: radial-gradient(ellipse at top,
            #7a4bc1 0%,
            #54338e 40%,
            #51328b 100%);
}

.hero h1 {
    font-size: 3.75rem;
    line-height: 1.2;
    font-weight: 500 !important;
    font-style: normal;
}

.hero p {
    font-size: 1.20rem !important;
    font-weight: 300 !important;
    font-style: normal;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--text-color);
    font-weight: 200 !important;
}

.navbar-light .navbar-brand img {
    height: 40px;
}

.btn-info {
    background-color: var(--secondary-color);
    color: var(--text-color);
    border: 0;
    width: 118px;
    height: 35px;
    font-weight: 300 !important;
    font-size: 0.875rem;
    line-height: 23px;
}

.btn-dark {
    border: 0;
    width: 118px;
    height: 35px;
    font-weight: 500 !important;
    font-size: 0.875rem;
}

.btn-dark:hover {
    border: 0;
    width: 118px;
    height: 35px;
    background-color: var(--background-color);
    color: var(--text-color-dark)
}

.card {
    transition: transform 0.3s;
}

.card:hover {
    transform: translateY(-5px);
}

.qr-menu-solutions img {
    width: 64px;
    height: 64px;
}

.pricing .card {
    border-radius: 15px;
}

footer a {
    color: #fff;
    text-decoration: none;
}

footer a:hover {
    color: #00c7b1;
}

@media (max-width: 768px) {
    .hero {
        padding-bottom: 150px !important;
        /* Reduce padding for mobile */
    }

    .hero-content {
        padding-top: 30px;
        /* Further reduce top padding for mobile */
    }

    .hero h1 {
        font-size: 2rem;
        /* Reduce font size for mobile */
    }

    .hero p {
        font-size: 1rem !important;
        /* Reduce font size for mobile */
    }

    .features {
        margin-top: 15% !important;
    }
}

.hero {
    padding-bottom: 300px;
}

.hero-content h1 {
    font-size: 2.5rem;
    line-height: 1.2;
}

.hero-content p {
    font-size: 1.1rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.hero-image {
    max-width: 100%;
    margin-top: 2rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: auto;
    z-index: 1;
}

.features {
    margin-top: 30%;
}

.navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.navbar-nav {
    align-items: center;
}

.navbar-nav .nav-link {
    color: #fff !important;
    font-weight: 500;
    padding: 0.5rem 1rem;
    margin-left: 15px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500 !important;
}

.navbar-brand img {
    height: 40px;
}

.qr-menu-solutions {
    background-color: #fff;
}

.features h2,
.menu-styles h2 {
    font-size: 2.10rem;
    font-weight: 700;
    letter-spacing: -0.72px;
}

.features p,
.menu-styles p {
    color: #1D2834;
    font-family: Inter;
    font-size: 14.25px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px !important;
    letter-spacing: 0.2px;
}

.features .feature-content,
.menu-styles .menu-styles-content {
    padding-left: 15px;
}

.features .feature-list,
.menu-styles .menu-styles-list {
    padding-left: 0;
    list-style-type: none;
}

.features .feature-list li,
.menu-styles .menu-styles-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #1D2834;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
}

.features .feature-list li i,
.menu-styles .menu-styles-list li i {
    font-size: 1rem;
    margin-right: 10px;
    color: var(--secondary-color);
}

.feature-card {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    transition: box-shadow 0.3s ease-in-out;
    background-color: transparent;
    text-align: center;
    padding: 1rem;
}

.feature-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    width: 48px !important;
    height: 48px !important;
}

.feature-card .card-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.feature-card .card-text {
    font-size: 0.8rem;
}

.qr-menu-solutions h2 {
    font-weight: bold;
    margin-bottom: 1rem;
}

.qr-menu-solutions p {
    color: #6c757d;
    margin-bottom: 2rem;
}

.gray-icon {
    filter: grayscale(100%) brightness(100%) contrast(100%);
}

.pricing .card {
    border-radius: 12px;
    transition: all 0.2s;
    box-shadow: none;
    border-color: #EAEFF4 !important;
    border-width: 1px !important;
    padding: 20px;
}

.pricing .card:hover {
    transform: scale(1.02);
}

.pricing ul li {
    font-size: 0.9rem;
}

.pricing .bi-check-circle {
    color: #28a745;
}

.pricing .bi-x-circle {
    color: #dc3545;
}

.pricing .bg-primary {
    background-color: var(--secondary-color) !important;
}

.pricing .btn-primary {
    background-color: var(--secondary-color);
    border-color: #5889c6;
}

.pricing .btn-primary:hover {
    background-color: var(--primary-color);
}

.pricing .price {
    font-size: 2.25rem !important;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
}

.pricing .package-name-title {
    font-size: 1.5rem !important;
    font-weight: 700;
    margin-top: 30px;
}

.pricing .package-name-subtitle {
    font-size: 0.75rem !important;
    font-weight: 300;
    margin-top: 10px;
}

.pricing button,
.pricing a,
.support button,
.support a {
    font-size: 1rem;
    border-radius: 200px !important;
    font-weight: 500;
    padding: 10px 25px;
}

.heading h2 {
    font-size: 2rem !important;
    font-weight: 700 !important;
}

.heading p {
    font-size: 1rem !important;
    font-weight: 300 !important;
    color: #1D2834;
}

.support {
    background-color: var(--secondary-color);
}

.support p {
    color: var(--text-color) !important;
}

.footer p,
ul,
li {
    font-size: 0.80rem !important;
}

.social-icons a:hover {
    opacity: 0.7;
}

@media (max-width: 767px) {
    .footer .social-icons {
        margin-top: 1rem;
        text-align: left;
    }
}

.clients {
    background-color: #fff;
    padding: 4rem 0;
}

.clients h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 3rem;
}

.client-logo {
    max-height: 140px;
    width: auto;
    transition: all 0.3s ease;
    filter: grayscale(100%);
    opacity: 0.7;
}

.client-logo:hover {
    transform: scale(1.1);
    filter: grayscale(0%);
    opacity: 1;
}

@media (max-width: 767px) {
    .client-logo {
        max-height: 80px;
    }
}

.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    z-index: 1000;
}

.whatsapp-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.4);
}